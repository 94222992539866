import React, { useState, useCallback, useEffect, useRef } from 'react'
import { GoogleMap, LoadScript, Polygon } from '@react-google-maps/api'
import { get, post } from '../helper/fetch'

const mapContainerStyle = {
  width: "100%",
  height: "400px",
}

const center = {
  lat: 51.0447,
  lng: -114.0719,
}

type AreaType = {
  id: number
  name: string
  paths: { lat: number; lng: number }[]
}
type RegionCenter = {
  [key in Region]: { lat: number; lng: number };
};

type Region = "Calgary" | "Edmonton" | "Vancouver" | "Ottawa" | "Oshawa";
const regionCenters: RegionCenter = {
  Calgary: { lat: 51.0447, lng: -114.0719 },
  Edmonton: { lat: 53.5461, lng: -113.4938 },
  Vancouver: { lat: 49.2827, lng: -123.1207 },
  Ottawa: { lat: 45.4215, lng: -75.6972 },
  Oshawa: { lat: 43.9333, lng: -78.8667 },
};

export default function AreaMapPicker() {
  const [region, setRegion] = useState<Region>("Calgary");
  const [selectedAreas, setSelectedAreas] = useState<AreaType[]>([]);
  const [existingPolygons, setExistingPolygons] = useState<AreaType[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [saveError, setSaveError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const mapRef = useRef<google.maps.Map | null>(null);

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newRegion = event.target.value as Region;
    setRegion(newRegion);
    if (mapRef.current) {
      mapRef.current.panTo(regionCenters[newRegion]);
    }
  };

  /**
   * Fetches both existing polygons and user's selected polygons
   */
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setIsLoading(true);

        // Fetch all polygons and selected polygon IDs in parallel
        const [polygonsData, selectedData] = await Promise.all([
          get<AreaType[]>("/map"),
          get<Number[]>("/map/my")
        ]);

        if (polygonsData.data) {
          setExistingPolygons(polygonsData.data);
          // Find and set selected areas based on the IDs
          const selectedPolygons = polygonsData.data.filter(polygon =>
            selectedData.data.includes(polygon.id)
          );
          setSelectedAreas(selectedPolygons);
        } else {
          setExistingPolygons([]);
          setSelectedAreas([]);
        }

      } catch (error) {
        console.error("Failed to fetch map data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchData();
  }, []);

  const toggleAreaSelection = useCallback((area: AreaType) => {
    const newSelectedAreas = selectedAreas.some(a => a.id === area.id)
      ? selectedAreas.filter(a => a.id !== area.id)
      : [...selectedAreas, area];

    setSelectedAreas(newSelectedAreas);
    handleSave(newSelectedAreas);
  }, [selectedAreas]);

  /**
   * Handles saving the selected areas to the server
   * @returns {Promise<void>}
   */
  const handleSave = async (areas: AreaType[]): Promise<void> => {
    try {
      setIsSaving(true);
      setSaveError("");

      await post("/map", areas);

      // Optional: Show success message or trigger a notification
    } catch (error) {
      setSaveError(typeof error === "string" ? error : "Failed to save selected areas");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Work Map</h1>
      <p className="text-md text-gray-700">
        Select the areas you want to work in.
      </p>

      <div className="gap-4">
        <div>
          {saveError && (
            <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
              {saveError}
            </div>
          )}
          <div className="flex flex-col">
            <div className="pt-4 pb-4 ">
              <p className="text-sm text-gray-600">
                1) Select the region you want to work in
              </p>
              <p className="text-sm text-gray-600 pb-4">
                2) Then select the areas you want to work in
              </p>
              <div className="pb-4">
                <label htmlFor="region" className="text-sm text-gray-600">
                  Region
                </label>
                <select
                  id="region"
                  value={region}
                  onChange={handleRegionChange}
                  className="block w-full px-3 py-2 text-base border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  {Object.keys(regionCenters).map(key => (
                    <option key={key} value={key}>
                      {key}
                    </option>
                  ))}
                </select>

                <p className="text-lg text-gray-700 pt-4 min-h-10 h-10">
                  {isSaving ? "Saving..." : "Green areas are selected, red areas are not selected"}
                </p>
              </div>
            </div>
          </div>
          <LoadScript googleMapsApiKey="AIzaSyCm5ChnsE7FPArmt7EiHsGcp99Z7EqbPTM">
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={regionCenters[region]}
              zoom={10}
              onLoad={map => {
                mapRef.current = map;
              }}
              options={{
                streetViewControl: false,
                fullscreenControl: false,
                mapTypeControl: false,
                zoomControl: true,
                clickableIcons: false,
                gestureHandling: "cooperative",
                minZoom: 8,
                maxZoom: 15,
                mapTypeId: "roadmap",

              }}
            >
              {isLoading ? (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                  <div className="text-gray-600">Loading map data...</div>
                </div>
              ) : (
                existingPolygons?.map(area => (
                  <React.Fragment key={area.id}>
                    <Polygon
                      paths={area.paths}
                      options={{
                        fillColor: selectedAreas.some(a => a.id === area.id) ? "#4CAF50" : "#FF0000",
                        fillOpacity: 0.5,
                        strokeColor: "#000000",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                      }}
                      onClick={() => toggleAreaSelection(area)}
                    />
                  </React.Fragment>
                ))
              )}
            </GoogleMap>
          </LoadScript>
        </div>
      </div>
    </div>
  )
}